import React from "react"
import Helmet from "react-helmet"
import striptags from "striptags"

export default React.memo(
    ({
        author,
        datePublished,
        dateModified,
        buildTime,
        description,
        siteUrl,
        isBlogPost,
        organizationDescription,
        title,
        url,
        legalName,
        name,
        logo,
        foundingDate
    }) => {
        const baseSchema = [
            {
                "@context": "http://schema.org",
                "@type": "Organization",
                url: siteUrl,
                name: name,
                address: {
                    "@type": "PostalAddress",
                    streetAddress: "53 Bridge St, 301",
                    addressLocality: "Brooklyn",
                    addressRegion: "NY",
                    postalCode: "11201",
                    addressCountry: "US"
                },
                legalName: legalName,
                description: striptags(organizationDescription),
                author,
                logo: logo,
                foundingDate: foundingDate,
                sameAs: [
                    `https://www.facebook.com/wearedescriptive`,
                    `https://twitter.com/wearedesc`,
                    `https://www.instagram.com/bydescriptive/`,
                    `https://www.linkedin.com/company/wearedescriptive`,
                    `https://github.com/wearedescriptive`,
                    `https://www.descriptive.co`
                ],
                founders: [
                    {
                        "@type": "Person",
                        name: `Johnnie Aiello`,
                        image: `https://descriptive-profile-images.s3.amazonaws.com/johnnie-profile-pic.jpg`,
                        jobTitle: `Founder`,
                        worksFor: {
                            "@type": `Organization`,
                            name: "Descriptive"
                        }
                    }
                ],
                contactPoint: [
                    {
                        "@type": "ContactPoint",
                        contactType: `careers`,
                        email: `careers@descriptive.co`
                    },
                    {
                        "@type": "ContactPoint",
                        contactType: `new business`,
                        email: `newbiz@descriptive.co`,
                        telephone: `+1-929-373-3778`
                    },
                    {
                        "@type": "ContactPoint",
                        contactType: `speaking engagements`,
                        email: `speaking@descriptive.co`
                    },
                    {
                        "@type": "ContactPoint",
                        contactType: `press`,
                        email: `press@descriptive.co`
                    }
                ]
            }
        ]

        const schema = isBlogPost
            ? [
                  ...baseSchema,
                  {
                      "@context": "http://schema.org",
                      "@type": "BreadcrumbList",
                      itemListElement: [
                          {
                              "@type": "ListItem",
                              position: 1,
                              item: {
                                  "@id": url,
                                  name: title
                              }
                          }
                      ]
                  },
                  {
                      "@context": "http://schema.org",
                      "@type": "BlogPosting",
                      "@id": url,
                      url: url,
                      name: name,
                      headline: title,
                      image: logo,
                      description: striptags(description),
                      datePublished: buildTime,
                      dateModified: buildTime,
                      author: {
                          "@type": "Organization",
                          name
                      },
                      publisher: {
                          "@type": "Organization",
                          name,
                          logo: {
                              "@type": "ImageObject",
                              url: logo
                          }
                      },
                      mainEntityOfPage: {
                          "@type": "WebPage",
                          "@id": url
                      }
                  }
              ]
            : baseSchema

        return (
            <Helmet>
                {/* Schema.org tags */}
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>
            </Helmet>
        )
    }
)
