import { useContext } from "react"
import { MyContext } from "../components/layout"

const useToggleFooterColor = ({ useDefault, useTan }) => {
    const { tanFooter, toggleFooterColor } = useContext(MyContext)
    console.log("useDefault", useDefault)
    console.log("useTan", useTan)
    if (useDefault) {
        if (tanFooter) {
            toggleFooterColor(false)
        }
    }
    if (useTan) {
        if (!tanFooter) {
            toggleFooterColor(true)
        }
    }
}

export default useToggleFooterColor
