import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import SchemaOrg from "./SEO/SchemaOrg"
import { useLocation } from "@reach/router"
import striptags from "striptags"

function SEO({
    description,
    lang,
    meta,
    keywords,
    image,
    title,
    isBlogPost,
    organization
}) {
    const { pathname } = useLocation()
    return (
        <StaticQuery
            query={detailsQuery}
            render={data => {
                const metaDescription =
                    description || data.site.siteMetadata.description
                const metaImage = image
                    ? image.src
                    : `${data.site.siteMetadata.image.src}`
                const metaImageAlt = image
                    ? image.alt
                    : `${data.site.siteMetadata.image.alt}`
                const metaUrl = `${data.site.siteMetadata.siteUrl}${pathname}`
                const sameAs = `${data.site.siteMetadata.socialLinks[0]}`
                const siteUrl = `${data.site.siteMetadata.siteUrl}`
                const organizationDescription = `${data.site.siteMetadata.description}`
                const buildTime = `${data.site.buildTime}`

                return (
                    <>
                        <Helmet
                            htmlAttributes={{
                                lang
                            }}
                            title={title}
                            meta={[
                                {
                                    name: `description`,
                                    content: striptags(metaDescription)
                                },
                                {
                                    property: `og:title`,
                                    content: title
                                },
                                {
                                    property: `og:url`,
                                    content: metaUrl
                                },
                                {
                                    property: `og:description`,
                                    content: striptags(metaDescription)
                                },
                                {
                                    property: `og:type`,
                                    content: isBlogPost ? `article` : `website`
                                },
                                {
                                    name: `twitter:creator`,
                                    content: `@${data.site.siteMetadata.social.twitter}`
                                },
                                {
                                    name: `twitter:title`,
                                    content: title
                                },
                                {
                                    name: `twitter:description`,
                                    content: striptags(metaDescription)
                                }
                            ]
                                .concat(
                                    metaImage
                                        ? [
                                              {
                                                  property: `og:image`,
                                                  content: metaImage
                                              },
                                              {
                                                  property: `og:image:alt`,
                                                  content: metaImageAlt
                                              },
                                              {
                                                  name: `twitter:card`,
                                                  content: `summary_large_image`
                                              },
                                              {
                                                  property: `twitter:image:alt`,
                                                  content: metaImageAlt
                                              }
                                          ]
                                        : [
                                              {
                                                  name: `twitter:card`,
                                                  content: `summary`
                                              }
                                          ]
                                )
                                .concat(
                                    metaImage && metaImage.indexOf("https") > -1
                                        ? [
                                              {
                                                  property:
                                                      "twitter:image:secure_url",
                                                  content: metaImage
                                              },
                                              {
                                                  property:
                                                      "og:image:secure_url",
                                                  content: metaImage
                                              }
                                          ]
                                        : []
                                )
                                .concat(
                                    keywords.length > 0
                                        ? {
                                              name: `keywords`,
                                              content: keywords.join(`, `)
                                          }
                                        : []
                                )
                                .concat(meta)}
                        />
                        <SchemaOrg
                            isBlogPost={isBlogPost}
                            url={metaUrl}
                            siteUrl={siteUrl}
                            title={title}
                            image={metaImage}
                            description={metaDescription}
                            organizationDescription={organizationDescription}
                            buildTime={buildTime}
                            canonicalUrl={siteUrl}
                            organization={organization}
                            defaultTitle={title}
                            legalName={
                                data.site.siteMetadata.organization.legalName
                            }
                            name={data.site.siteMetadata.organization.name}
                            logo={data.site.siteMetadata.organization.logo}
                            sameAs={sameAs}
                            foundingDate={data.site.siteMetadata.foundingDate}
                        />
                    </>
                )
            }}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    keywords: [],
    pathname: ``
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    image: PropTypes.object,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    pathname: PropTypes.string,
    title: PropTypes.string.isRequired
}

export default SEO

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            buildTime(formatString: "YYYY-MM-DD")
            siteMetadata {
                title
                siteUrl
                description
                author
                foundingDate
                image {
                    src
                    alt
                }
                social {
                    twitter
                }
                socialLinks {
                    twitter
                    linkedin
                    facebook
                    github
                    instagram
                }
                organization {
                    name
                    url
                    logo
                    legalName
                    email
                    phone
                }
            }
        }
    }
`
